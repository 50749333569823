import Link from "next/link";

import FacebookLogo from "../../public/icons/FacebookLogo";
import InstagramLogo from "../../public/icons/InstagramLogo";
import Button from "../Buttons/Button";
import Input from "../Input/Input";
import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <>
      <div className={styles.footerContainer}>
        <div className={`app-max-width app-x-padding ${styles.footerContents}`}>
          <div>
            <h3 className={styles.footerHead}>Company</h3>
            <div className={styles.column}>
              <Link href='/coming-soon'>About us</Link>
              <Link href='/coming-soon'>Contact us</Link>
              <Link href='/coming-soon'>Store location</Link>
              <Link href='/coming-soon'>Careers</Link>
            </div>
          </div>
          <div>
            <h3 className={styles.footerHead}>Help</h3>
            <div className={styles.column}>
              <Link href='/coming-soon'>Order tracing</Link>
              <Link href='/coming-soon'>FAQs</Link>
              <Link href='/coming-soon'>Privacy policy</Link>
              <Link href='/coming-soon'>Terms and conditions</Link>
            </div>
          </div>
          <div>
            {/* <h3 className={styles.footerHead}>Store</h3> */}
            {/* <div className={styles.column}>
              <Link legacyBehavior href={`/product-category/women`}>
                <a>Women</a>
              </Link>
              <Link legacyBehavior href={`/product-category/men`}>
                <a>Men</a>
              </Link>
              <Link legacyBehavior href={`/product-category/bags`}>
                <a>Bags</a>
              </Link>
            </div> */}
          </div>
          <div>
            <h3 className={styles.footerHead}>Keep in touch</h3>
            {/* <div className={styles.column}>
              <span>
                {t("address.detail")}
                <br />
                {t("address.road")}
                <br />
                {t("address.city")}
              </span>
              <span>{t("phone_number")}</span>
              <span>
                {t("open_all_days")} <br />- {t("opening_hours")}
              </span>
            </div> */}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center pb-16'>
        <h4 className='text-3xl mb-4'>Newsletter</h4>
        <span className='px-6 text-center'>
          Be the first to know about new arrivals, sales & promos!
        </span>
        <div className='mt-5 px-6 flex w-full sm:w-auto flex-col sm:flex-row'>
          <Input
            label='Newsletter Input Box'
            name='email'
            type='email'
            extraClass=' w-full sm:w-auto'
          />{" "}
          <Button
            size='lg'
            value={"Send"}
            extraClass='ml-0 mt-4 sm:mt-0 tracking-widest sm:tracking-normal sm:mt-0 sm:ml-4 w-auto w-full sm:w-auto'
          />
        </div>
      </div>
      <div className={styles.bottomFooter}>
        <div className='app-max-width app-x-padding w-full flex justify-between'>
          <span className=''>
            @2023 The Kingdom Boutique All rights reserved
          </span>
          <span className='flex items-center'>
            <span className='hidden sm:block'>Follow us on Social media:</span>{" "}
            <a href='www.facebook.com'>
              <FacebookLogo />
            </a>
            <a href='www.ig.com'>
              <InstagramLogo />
            </a>
          </span>
        </div>
      </div>
    </>
  );
}
