import { FC, useState } from "react";
import Link from "next/link";
import Image from "next/image";

import Heart from "../../public/icons/Heart";
import styles from "./Card.module.css";
import HeartSolid from "../../public/icons/HeartSolid";
import { itemType } from "../../context/cart/cart-types";
import { useCart } from "../../context/cart/CartProvider";
import { useWishlist } from "../../context/wishlist/WishlistProvider";

type Props = {
  item: itemType;
};

const Card: FC<Props> = ({ item }) => {
  const { wishlist, addToWishlist, deleteWishlistItem } = useWishlist();
  const { addOne } = useCart();
  const [isHovered, setIsHovered] = useState(false);
  const [isWLHovered, setIsWLHovered] = useState(false);

  const { id, name, price, img1, img2 } = item;

  const itemLink = `/products/${encodeURIComponent(id)}`;

  const alreadyWishlisted =
    wishlist.filter((wItem) => wItem.id === id).length > 0;

  const handleWishlist = () => {
    alreadyWishlisted ? deleteWishlistItem!(item) : addToWishlist!(item);
  };

  return (
    <div className={styles.card} onClick={() => addOne!(item)}>
      <div className={styles.imageContainer}>
        <a
          tabIndex={-1}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {!isHovered && (
            <Image
              src={img1 as string}
              alt={name}
              width={230}
              height={300}
              layout='responsive'
            />
          )}
          {isHovered && (
            <Image
              className='transition-transform transform hover:scale-110 duration-1000'
              src={img2 as string}
              alt={name}
              width={230}
              height={300}
              layout='responsive'
            />
          )}
        </a>
        <button
          type='button'
          className='absolute top-2 right-2 p-1 rounded-full'
          aria-label='Wishlist'
          onClick={handleWishlist}
          onMouseOver={() => setIsWLHovered(true)}
          onMouseLeave={() => setIsWLHovered(false)}
        >
          {isWLHovered || alreadyWishlisted ? <HeartSolid /> : <Heart />}
        </button>
        <button
          type='button'
          onClick={() => addOne!(item)}
          className={styles.addBtn}
        >
          Add to cart
        </button>
      </div>

      <div className='content'>
        <Link legacyBehavior href={itemLink}>
          <a className={styles.itemName}>{name}</a>
        </Link>
        <div className='text-gray400'>€ {price}</div>
        <button
          type='button'
          onClick={() => addOne!(item)}
          className='uppercase font-bold text-sm sm:hidden'
        >
          Add to cart
        </button>
      </div>
    </div>
  );
};

export default Card;
