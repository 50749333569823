import { Menu } from "@headlessui/react";
import { useRouter } from "next/router";
import Link from "next/link";

import InstagramLogo from "../../public/icons/InstagramLogo";
import FacebookLogo from "../../public/icons/FacebookLogo";
import DownArrow from "../../public/icons/DownArrow";
import styles from "./Header.module.css";

type LinkProps = {
  href: string;
  locale: "en" | "my";
  active: boolean;
  children?: React.ReactNode;
};

const MyLink: React.FC<LinkProps> = ({
  href,
  locale,
  children,
  active,
  ...rest
}) => {
  return (
    <Link legacyBehavior href={href} locale={locale}>
      <a
        className={`py-2 px-4 text-center ${
          active ? "bg-gray200 text-gray500" : "bg-white text-gray500"
        }`}
        {...rest}
      >
        {children}
      </a>
    </Link>
  );
};

const TopNav = () => {
  const router = useRouter();
  const { asPath, locale } = router;

  return (
    <div className='bg-gray500 text-gray100 hidden lg:block'>
      <div className='flex justify-between app-max-width'>
        <ul className={`flex ${styles.topLeftMenu}`}>
          <li>
            <a href='#'>
              <FacebookLogo />
            </a>
          </li>
          <li>
            <a href='#'>
              <InstagramLogo />
            </a>
          </li>
          <li>
            <a href='#'>About us</a>
          </li>
          <li>
            <a href='#'>Privacy policy</a>
          </li>
        </ul>
        <ul className={`flex ${styles.topRightMenu}`}>
          <li>
            <Menu as='div' className='relative'>
              <Menu.Button as='a' href='#' className='flex'>
                EUR <DownArrow />
              </Menu.Button>
              <Menu.Items
                className='flex flex-col w-20 right-0 absolute p-1 border border-gray200 bg-white mt-2 outline-none'
                style={{ zIndex: 9999 }}
              >
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='#'
                      className={`${
                        active
                          ? "bg-gray100 text-gray500"
                          : "bg-white text-gray500"
                      } py-2 px-4 text-center focus:outline-none`}
                    >
                      EUR
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopNav;
