import React from "react";
import Head from "next/head";

type Props = {
  title?: string;
  desc?: string;
  keywords?: string;
};

const AppHeader: React.FC<Props> = ({ title = "The Kingdom Boutique" }) => {
  return (
    <Head>
      <title>{title}</title>

      <meta property='og:title' content={title} key='og_title' />
      <meta name='twitter:title' content={title} key='twitter_title' />
    </Head>
  );
};

export default AppHeader;
